import { Script } from "gatsby";
import React from "react";
import "./src/styles/global.css";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script src='https://rsms.me/inter/inter.css' />
    </>
  );
};
